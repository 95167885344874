import React from "react"
import MapLondon from "./map-london"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Logo from "../UX/Logo"
import { Link } from "gatsby-plugin-modal-routing-3"
import { RevealTitle } from "../UX/VisibilitySensor"

const OpenButton = styled(Link)`
  position: absolute;
  bottom: -15px;
  right: 15px;
  transition: transform 300ms;

  :hover {
    transform: scale(1.3);
  }
`

const ContactSection = ({ data }) => {
  const { title, offices } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  //Generate Rob and Chris' details
  const Offices = offices.map(office => {
    const { image, email, location, name, telephone } = office
    let slug = name.replace(/\s+/g, "-").toLowerCase()
    return (
      <div key={location} className="flex-2">
        <div style={{ position: "relative" }}>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
            style={{
              position: "relative",
              maxWidth: "200px",
              width: "-webkit-fill-available",
              boxShadow: "var(--cube-box-shadow)",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          />
          <OpenButton asModal to={`/member/${slug}`}>
            <div className="plus-button" />
          </OpenButton>
        </div>
        <div>
          <h2 style={{ textAlign: "left" }}>{name}</h2>
          <br></br>
          <h3>{telephone}</h3>
          <h3>{email}</h3>
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="container grid-by-4">
        <Logo />
        <div className="grid-right">
          <RevealTitle barStyle="right" directionUp={false}>
            <h1 style={{ textAlign: "left" }}>{title}</h1>
          </RevealTitle>
        </div>
      </div>
      <div className="container">
        <div className="flex-2">{Offices}</div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "black",
            margin: "70px 0",
          }}
        />
        <div>
          <MapLondon
            location={offices[0].location}
            address={offices[0].address}
          />
        </div>
      </div>
    </>
  )
}

export default ContactSection
