import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"

import ContactSection from "../components/UX/contact-section"
import Section from "../components/UX/Section"

const Contact = ({ data }) => {
  return (
    <Layout>
      <Seo title="Contact" />
      <Section>
        <ContactSection data={data} />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "contact" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              subtitle
              offices {
                address
                email
                location
                name
                telephone
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                      layout: CONSTRAINED
                      width: 300
                      height: 300
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Contact
