import React from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import Logo from "./Logo"

const containerStyle = {
  width: "-webkit-fill-available",
  height: "300px",
  boxShadow: "var(--cube-box-shadow)",
  borderRadius: "6px",
}

const center = {
  lat: 51.52074776866041,
  lng: -0.12220980200518139,
}

const mapOptions = {
  panControl: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  overviewMapControl: false,
  rotateControl: false,
}

const key = process.env.GATSBY_GOOGLE_MAPS_STATIC_API_KEY

const MapLondon = ({ location, address }) => {
  return (
    <div className="flex-2">
      <div className="flex-col">
        <h2 style={{ textAlign: "left" }}>{location} Office</h2>
        <h3
          dangerouslySetInnerHTML={{ __html: address }}
          style={{ whiteSpace: "pre-wrap" }}
        />
      </div>
      <LoadScript googleMapsApiKey={key}>
        <GoogleMap
          options={mapOptions}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
        >
          <>
            <Marker position={center} />
            <InfoWindow position={center} options={{ maxWidth: 100 }}>
              <Logo />
            </InfoWindow>
          </>
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default React.memo(MapLondon)
